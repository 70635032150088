@use 'sass:map';
@use '_theme.scss' as theme;
@use '../../shared/_theme.scss' as default;
@use '../../shared/index.scss' as shared;

@font-face {
  font-family: 'AvantGardeITC';
  src: url(./styles/themes/evo-norway/fonts/296048_1_0.woff) format('woff');
}

@font-face {
  font-family: 'AvantGardeITCBold';
  src: url(./styles/themes/evo-norway/fonts/bold.woff) format('woff');
}

$theme: map.merge(default.$theme-vars, theme.$theme-vars);

.evo-norway {
  // Typography
  @import url(//fonts.googleapis.com/css?family=Open+Sans:100,200,400,700,900);

  // Apply theme to shared styles
  @include shared.styles($theme);
}
