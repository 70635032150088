@use 'sass:map';

@mixin style($theme) {
  /*
   * Credit card form
   *
   * Note: cannot use flex layout because of Verifone iframe resizer (nothing displayed)
   */
  .credit-card-container {
    font-family: map.get($theme, 'font-family-credit-card');
    margin-left: auto;
    margin-right: auto;
    max-width: 40em;

    .quote {
      .presentation {
        display: none;
      }

      .charges {
        background: map.get($theme, 'white');
        color: map.get($theme, 'black-1');
        padding: 0 2em;
      }
    }
  }
}
