// Define styles for each theme.
//
// Each theme defines variables expected by shared styles.
// Then shared styles are imported into/below a class
// selector carrying the theme name. This way, although duplicated,
// the themed styles will not interfere with each other.
//
// The App components ensures current theme name is injected
// into the HTML element as a class.
@use 'styles/themes/evo-norway/index.scss' as no;
@use 'styles/themes/evo-hp/index.scss' as hp;

// Styles for top level HTML element cannot be defined within themes
html {
  height: 100%;
}
