@use 'sass:map';

@mixin style($theme) {
  body {
    color: map.get($theme, 'black-1');
    font-size: map.get($theme, 'font-size-default');
    font-family: map.get($theme, 'font-family-default');
    line-height: normal;
    font-weight: normal;
  }
}
