@use '_breakpoints.scss' as breaks;
@use 'sass:map';

@mixin style($theme) {
  // Best way found within current layout to move quote presentation around based on screensize
  #quote-presentation-mobile {
    display: none;

    @include breaks.for-phone-only {
      margin: 0 1rem;
      padding: .5rem 1rem;
      background: linear-gradient(145.4deg, map.get($theme, 'gradient-start') 0%, map.get($theme, 'gradient-stop-2') 100%);
      display: block;
      grid-area: quote-presentation;
      p {
        margin: 0;
      }
    }
  }

  .quote {
    // Move to align with location select (and not its label)
    margin-top: 2rem;

    .presentation {
      color: map.get($theme, 'white');
      padding: 0.5rem 1rem 1rem 1rem;
      background: linear-gradient(145.4deg, map.get($theme, 'gradient-start') 0%, map.get($theme, 'gradient-stop-2') 100%);
      p {
        margin: 0 0 1rem 0;
      }
      h4 {
        font-weight: bold;
        margin: 0.5rem 0;
      }

      // Best way found within current layout to move quote presentation around based on screensize
      @include breaks.for-phone-only {
        display: none;
      }
    }

    .charges {
      margin: 1rem 0;
      padding: 0.5rem 1rem 1rem 1rem;
      color: map.get($theme, 'white');

      table {
        width: 100%;
        border-spacing: 0;

        caption {
          text-align: left;
          font-weight: bold;
          margin-bottom: 0.5em;
        }

        tbody {
          td {
            font-size: 0.9em;
          }

          // Separate quote lines a line
          tr + tr {
            td {
              padding-top: .4em;
              border-top: 1px solid silver;
            }
          }
        }

        tbody, tfoot {
          td:last-child {
            text-align: right;
          }

        }

        tfoot {
          td:first-child {
            text-transform: uppercase;
          }

          td {
            font-weight: bold;
            padding-top: 1em;
          }
        }
      }

      .next-payment {
        font-size: 0.6125em;
      }
    }
  }
}

