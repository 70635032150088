@use 'sass:map';
@use '_theme.scss' as theme;
@use '../../shared/_theme.scss' as default;
@use '../../shared/index.scss' as shared;

$theme: map.merge(default.$theme-vars, theme.$theme-vars);

.evo-hp {
  // Typography
  @import url(//fonts.googleapis.com/css?family=Roboto:100,200,400,700,900);

  // Apply theme to shared styles
  @include shared.styles($theme);
}
